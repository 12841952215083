import {
  SIGNUP,
  UPDATE_OPTION_LOGIN,
  UPDATE_OPTION_SIGNUP,
} from 'constants/signupConstants'

const defaultState = {
  option: SIGNUP,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_OPTION_LOGIN: {
      return {
        option: action.option,
      }
    }
    case UPDATE_OPTION_SIGNUP: {
      return {
        option: action.option,
      }
    }
    default:
      return state
  }
}
