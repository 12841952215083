import * as configcat from 'configcat-js'
import { isNil } from 'lodash'
import {
  CONFIG_CAT_FAILURE,
  CONFIG_CAT_REQUEST,
  CONFIG_CAT_SUCCESS,
} from 'constants/featureFlagConstants'

const configCatRequest = () => ({
  type: CONFIG_CAT_REQUEST,
})

const configCatSuccess = (client, flags) => ({
  type: CONFIG_CAT_SUCCESS,
  client,
  flags,
})

const configCatFailure = () => ({
  type: CONFIG_CAT_FAILURE,
})

export const loadConfigCat = user => {
  return async dispatch => {
    try {
      dispatch(configCatRequest())
      const logger = configcat.createConsoleLogger(-1)
      const configCatClient = configcat.createClient(
        process.env.GATSBY_CONFIG_CAT,
        {
          logger,
        }
      )
      const allKeys = await configCatClient.getAllKeysAsync()
      const flags = []

      const userObject = {
        identifier: user.id.toString(),
      }

      allKeys.map(async key => {
        flags[key] = await configCatClient.getValueAsync(key, false, userObject)
      })

      dispatch(configCatSuccess(configCatClient, flags))
    } catch (err) {
      if (!isNil(user)) {
        dispatch(configCatFailure())
      }
    }
  }
}
