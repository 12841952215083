import { analytics } from './firebase'

export const addPaymentInfo = platform => {
  analytics().logEvent('add_payment_info', {
    payment_type: platform,
  })
}

export const addShippingInfoEvent = (
  listingId,
  modelId,
  modelName,
  quantity
) => {
  analytics().logEvent('add_shipping_info', {
    items: [
      {
        item_id: listingId,
        item_name: modelName,
        item_brand: modelId,
        quantity,
      },
    ],
  })
}

export const loginEvent = method => {
  analytics().logEvent('login', {
    method,
  })
}

export const purchaseEvent = (
  transactionId,
  listingPrice,
  shippingFee,
  listingId,
  modelName,
  modelId,
  quantity
) => {
  analytics().logEvent('purchase', {
    currency: 'USD',
    transaction_id: transactionId,
    shipping: shippingFee,
    value: listingPrice,
    items: [
      {
        currency: 'USD',
        item_id: listingId,
        item_name: modelName,
        item_brand: modelId,
        quantity,
      },
    ],
  })
}

export const searchEvent = searchTerm => {
  analytics().logEvent('search', {
    search_term: searchTerm,
  })
}

export const signupEvent = method => {
  analytics().logEvent('sign_up', {
    method,
  })
}
