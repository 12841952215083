import {
  FETCH_CATALOG_FAILURE,
  FETCH_CATALOG_REQUEST,
  FETCH_CATALOG_SUCCESS,
} from 'constants/catalogConstants'

const defaultState = {
  isLoading: false,
  catalog: {
    categories: [],
    manufacturers: [],
    material_colors: [],
    materials: [],
    print_technology: [],
  },
  error: '',
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_CATALOG_FAILURE: {
      return {
        ...state,
        error: action.error,
        isLoading: false,
      }
    }
    case FETCH_CATALOG_REQUEST: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case FETCH_CATALOG_SUCCESS: {
      return {
        ...state,
        catalog: action.catalog,
        isLoading: false,
      }
    }
    default:
      return state
  }
}
