import { render } from 'react-dom'
import wrapWithProvider from './wrap-with-provider'
import { store } from './src/store'
import { fetchAllCatalog } from 'actions/catalogActions'
import { loadConfigCat } from 'actions/featureFlagActions'
import {
  fetchCurrentUserRequest,
  fetchCurrentUserSuccess,
} from 'actions/userActions'
import {
  createAnonymousUser,
  deleteAnonymousUser,
  getCurrentUser,
} from './src/services/UserService'

const { dispatch } = store

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const loadableReady = require('@loadable/component').loadableReady

export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    loadableReady(() => {
      render(element, container, callback)
    })
  }
}

export const onClientEntry = async () => {
  dispatch(fetchCurrentUserRequest())
  try {
    let user = localStorage.getItem('user')
    if (user) {
      user = JSON.parse(user)
    } else {
      user = await getCurrentUser()
    }
    dispatch(fetchCurrentUserSuccess(user))
    dispatch(loadConfigCat({ id: user.uid }))
  } catch (err) {
    const user = await createAnonymousUser()
    dispatch(fetchCurrentUserSuccess(user))
    dispatch(loadConfigCat({ id: user.uid }))
  }
}

export const onInitialClientRender = async () => {
  await fetchAllCatalog()
}

window.onbeforeunload = () => {
  let user = localStorage.getItem('user')
  user = JSON.parse(user)
  if (user && user.isAnonymous) {
    deleteAnonymousUser()
  }
  return null
}

export const wrapRootElement = wrapWithProvider
