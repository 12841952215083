import {
  UPDATE_REFRESH_TRUE,
  UPDATE_REFRESH_FALSE,
} from 'constants/algoliaConstants'

const defaultState = {
  refresh: false,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_REFRESH_TRUE: {
      return {
        refresh: true,
      }
    }
    case UPDATE_REFRESH_FALSE: {
      return {
        refresh: false,
      }
    }
    default:
      return state
  }
}
