import {
  FETCH_CURRENT_USER_REQUEST,
  FETCH_CURRENT_USER_SUCCESS,
  LOG_OUT_REQUEST,
  LOG_OUT_SUCCESS,
  UPDATE_CURRENT_USER_FAILURE,
  UPDATE_CURRENT_USER_REQUEST,
  UPDATE_CURRENT_USER_SUCCESS,
} from 'constants/userConstants'

const defaultState = {
  isLoading: false,
  isLoggingOut: false,
  user: null,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_CURRENT_USER_REQUEST: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case FETCH_CURRENT_USER_SUCCESS: {
      return {
        ...state,
        user: action.user,
        isLoading: false,
      }
    }
    case LOG_OUT_REQUEST: {
      return {
        ...state,
        isLoggingOut: true,
      }
    }
    case LOG_OUT_SUCCESS: {
      return {
        ...state,
        isLoggingOut: false,
      }
    }
    case UPDATE_CURRENT_USER_FAILURE: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case UPDATE_CURRENT_USER_REQUEST: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case UPDATE_CURRENT_USER_SUCCESS: {
      return {
        ...state,
        user: action.user,
        isLoading: false,
      }
    }
    default:
      return state
  }
}
