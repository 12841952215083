import { navigate } from 'gatsby'
import * as Firebase from 'firebase'
import getFirebase from './firebase'
import {
  fetchCurrentUserSuccess,
  logOutRequest,
  logOutSuccess,
} from '../actions/userActions'
import {
  HOME_ENDPOINT,
  ONBOARDING_ENDPOINT,
  SIGNUP_ENDPOINT,
  SIGNUP_INSTRUCTIONS_ENDPOINT,
} from 'constants/apiUrls'
import { OPERATOR_MAIL } from 'constants/mailEndpointConstant'
import { EMAIL_PASSWORD, GOOGLE } from 'constants/signupConstants'
import {
  ERROR_EMAIL_UNVERIFIED,
  ERROR_NOT_LOGGED_IN,
  ERROR_USER_NOT_IN_DB,
  USER_STATUS_ACTIVE,
  USER_STATUS_PENDING_ACTIVATION,
} from 'constants/userConstants'
import { loginEvent, signupEvent } from 'services/GoogleAnalyticsService'
import { store } from '../store'
const { dispatch } = store

const firebaseApp = getFirebase()
const getAuth = () => firebaseApp.auth()

export const getUser = uid =>
  firebaseApp.firestore().collection('users').doc(uid)

export const getUserById = uid => {
  return new Promise((resolve, reject) => {
    getUser(uid)
      .get()
      .then(doc => {
        if (doc.exists) {
          resolve(doc.data())
        } else {
          reject(new Error(ERROR_USER_NOT_IN_DB))
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}
const googleAuthProvider = () => new Firebase.auth.GoogleAuthProvider()

const createUser = (user, signUpMethod) => {
  return new Promise((resolve, reject) => {
    const { email, photoURL, uid } = user
    const isOperator =
      email.endsWith(OPERATOR_MAIL.SMTHY) ||
      email.endsWith(OPERATOR_MAIL.CODEFABRIC)

    return getUser(uid)
      .set({
        created_at: Firebase.firestore.FieldValue.serverTimestamp(),
        email,
        isMaker: false,
        isOperator,
        onboarded: false,
        photo_url: photoURL,
        status: USER_STATUS_PENDING_ACTIVATION,
        uid,
        updated_at: Firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        signupEvent(signUpMethod)
        resolve(user)
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}

const updateReduxUser = newUser => {
  return dispatch(fetchCurrentUserSuccess(newUser))
}

let anonymousUser

export const createAnonymousUser = () => {
  return new Promise((resolve, reject) => {
    getAuth()
      .signInAnonymously()
      .then(user => {
        anonymousUser = getAuth().currentUser
        updateReduxUser(anonymousUser)
        localStorage.setItem('user', JSON.stringify(anonymousUser))
        localStorage.setItem('catalog', '')
        dispatch(logOutSuccess())
        resolve(anonymousUser)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const deleteAnonymousUser = () => {
  if (anonymousUser) {
    try {
      anonymousUser
        .delete()
        .then(() => {
          anonymousUser = null
        })
        .catch(error => {
          console.log(error.message)
        })
    } catch (error) {
      console.log(error.message)
    }
  }
}

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    if (firebaseApp) {
      getAuth().onAuthStateChanged(user => {
        if (user) {
          if (!user.isAnonymous) {
            getUser(user.uid)
              .get()
              .then(doc => {
                if (doc.exists) {
                  const { emailVerified } = user
                  resolve({ emailVerified, ...doc.data() })
                } else {
                  reject(new Error(ERROR_USER_NOT_IN_DB))
                }
              })
              .catch(error => {
                console.log(error.message)
                reject(error)
              })
          } else {
            anonymousUser = getAuth().currentUser
            resolve({ emailVerified: false, ...user })
          }
        } else {
          reject(new Error(ERROR_NOT_LOGGED_IN))
        }
      })
    }
  })
}

export const loginWithEmailPassword = credentials => {
  return new Promise((resolve, reject) => {
    if (firebaseApp) {
      const { email, password } = credentials
      getAuth()
        .signInWithEmailAndPassword(email, password)
        .then(response => {
          const { user } = response
          deleteAnonymousUser()
          getUser(user.uid)
            .get()
            .then(doc => {
              if (doc.exists) {
                const { emailVerified } = user
                resolve({ emailVerified, ...doc.data() })
                updateReduxUser({ emailVerified, ...doc.data() })
                loginEvent(EMAIL_PASSWORD)
                localStorage.setItem('user', JSON.stringify(doc.data()))
                resolve({ emailVerified, ...doc.data() })
                navigate(HOME_ENDPOINT)
              } else {
                reject(new Error(ERROR_USER_NOT_IN_DB))
              }
            })
            .catch(error => {
              console.log(error.message)
              reject(error)
            })
        })
        .catch(error => {
          console.log(error.message)
          reject(error)
        })
    }
  })
}

export const logout = () => {
  dispatch(logOutRequest())
  return new Promise((resolve, reject) => {
    if (firebaseApp) {
      getAuth()
        .signOut()
        .then(() => {
          createAnonymousUser()
            .then(() => {
              navigate(SIGNUP_ENDPOINT)
              resolve('Logged out successfully.')
            })
            .catch(error => {
              console.log(error.message)
              reject(error)
            })
        })
        .catch(error => {
          console.log(error.message)
          reject(error)
        })
    }
  })
}

const redirectUser = data => {
  const { onboarded } = data
  onboarded
    ? navigate(HOME_ENDPOINT)
    : navigate(ONBOARDING_ENDPOINT, { state: { user: data } })
}

export const signInOrUpWithGoogle = () => {
  return new Promise((resolve, reject) => {
    if (firebaseApp) {
      getAuth()
        .signInWithPopup(googleAuthProvider())
        .then(response => {
          const { user } = response
          deleteAnonymousUser()
          getUser(user.uid)
            .get()
            .then(async doc => {
              if (doc.exists) {
                const user = doc.data()
                user.emailVerified = firebaseApp.auth().currentUser.emailVerified
                loginEvent(GOOGLE)
                updateReduxUser(user)
                redirectUser(user)
                localStorage.setItem('user', JSON.stringify(user))
              } else {
                await createUser(user, GOOGLE)
                const createdUser = await getUser(user.uid).get()
                const newUser = createdUser.data()
                newUser.emailVerified = firebaseApp.auth().currentUser.emailVerified
                updateReduxUser(newUser)
                localStorage.setItem('user', JSON.stringify(newUser))
                resolve(newUser)
                navigate(ONBOARDING_ENDPOINT, {
                  state: { user: newUser },
                })
              }
            })
            .catch(error => {
              console.log(error.message)
              reject(error)
            })
        })
        .catch(error => {
          console.log(error.message)
          reject(error)
        })
    }
  })
}

export const signUpWithEmailPassword = credentials => {
  return new Promise((resolve, reject) => {
    if (firebaseApp) {
      const { email, password } = credentials
      const actionCodeSettings = {
        url: `${process.env.GATSBY_API_URL}${ONBOARDING_ENDPOINT}`,
        handleCodeInApp: true,
      }
      getAuth()
        .createUserWithEmailAndPassword(email, password)
        .then(response => {
          const { user } = response
          deleteAnonymousUser()
          return user
            .sendEmailVerification(actionCodeSettings)
            .then(() => {
              return createUser(user, EMAIL_PASSWORD)
                .then(result => {
                  navigate(SIGNUP_INSTRUCTIONS_ENDPOINT)
                  updateReduxUser(result)
                  resolve(result)
                })
                .catch(error => {
                  console.log(error.message)
                  reject(error)
                })
            })
            .catch(error => {
              console.log(error.message)
              reject(error)
            })
        })
        .catch(error => {
          console.log(error.message)
          reject(error)
        })
    }
  })
}

export const updateUserPersonalInfo = user => {
  return new Promise((resolve, reject) => {
    const {
      emailVerified,
      firstName: first_name,
      lastName: last_name,
      phoneNumber: phone_number,
      photoUrl: photo_url,
      uid,
      shippingInfo: {
        city,
        companyAddress: company_address,
        companyName: company_name,
        country,
        state,
        zipCode: zip_code,
      },
    } = user
    if (emailVerified) {
      getUser(uid)
        .update({
          badges: ['Early Adopter'],
          photo_url,
          first_name,
          last_name,
          isMaker: false,
          phone_number,
          onboarded: true,
          status: USER_STATUS_ACTIVE,
          shipping_info: {
            city,
            company_address,
            company_name,
            country,
            state,
            zip_code,
          },
          tutorial_state: { pageHeader: false },
          updated_at: Firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          getUser(user.uid)
            .get()
            .then(doc => {
              if (doc.exists) {
                resolve({ emailVerified, ...doc.data() })
                localStorage.setItem('user', JSON.stringify(doc.data()))
                updateReduxUser({ emailVerified, ...doc.data() })
                resolve({ emailVerified, ...doc.data() })
              } else {
                reject(new Error(ERROR_USER_NOT_IN_DB))
              }
            })
            .catch(error => {
              console.log(error.message)
              reject(error)
            })
        })
        .catch(error => {
          console.log(error.message)
          reject(error)
        })
    } else {
      reject(new Error(ERROR_EMAIL_UNVERIFIED))
    }
  })
}

export const updateTutorialState = (userId, tutorialState) => {
  return new Promise((resolve, reject) => {
    return getUser(userId)
      .update({
        tutorial_state: tutorialState,
        updated_at: Firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        resolve(tutorialState)
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}

export const updateShippingInfo = (userId, shippingInfo, phoneNumber) => {
  return new Promise((resolve, reject) => {
    return getUser(userId)
      .update({
        phone_number: phoneNumber,
        shipping_info: shippingInfo,
        updated_at: Firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        resolve(shippingInfo)
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}

export const updateStripeUserInfo = (userId, params) => {
  return new Promise((resolve, reject) => {
    return getUser(userId)
      .update({
        ...params,
      })
      .then(() => {
        resolve(params)
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}

export const updateUserInfo = (userId, params) => {
  return new Promise((resolve, reject) => {
    return getUser(userId)
      .update({
        ...params,
        updated_at: Firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        resolve(params)
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}

export const sendPasswordResetEmailToUser = email => {
  const auth = getAuth()
  return new Promise((resolve, reject) => {
    auth
      .fetchSignInMethodsForEmail(email)
      .then(results => {
        const emailAccountHavePassword = results.includes('password')
        if (emailAccountHavePassword) {
          auth
            .sendPasswordResetEmail(email)
            .then(() => {
              resolve(emailAccountHavePassword)
            })
            .catch(error => {
              reject(error.message)
            })
        } else {
          resolve(emailAccountHavePassword)
        }
      })
      .catch(error => {
        reject(error.message)
      })
  })
}

export const updateUserTechDetails = (userId, techDetails, user) => {
  const { manufacturer, model, primaryMaterial, colors } = techDetails
  return new Promise((resolve, reject) => {
    return getUser(userId)
      .update({
        integrated_with_stripe: false,
        technical_details: {
          manufacturer: manufacturer,
          model: model,
          primary_material: primaryMaterial,
          colors: colors,
        },
        updated_at: Firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        resolve(techDetails)
        updateReduxUser({
          ...user,
          technical_details: {
            manufacturer: manufacturer,
            model: model,
            primary_material: primaryMaterial,
            colors: colors,
          },
        })
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}

export const addMakerReview = (
  designId,
  listingId,
  makerId,
  makerReview,
  modelName,
  reviewer
) => {
  const {
    qualityAccuracy,
    profesionalism,
    price,
    promptness,
    notes,
  } = makerReview
  const { firstName, lastName, photoUrl, uid } = reviewer
  return new Promise((resolve, reject) => {
    return firebaseApp
      .firestore()
      .collection('users')
      .doc(makerId)
      .collection('reviews')
      .add({
        model_name: modelName,
        design_id: designId,
        comment_review: notes,
        listing_id: listingId,
        quality_accuracy: qualityAccuracy,
        profesionalism: profesionalism,
        price: price,
        promptness: promptness,
        reviewer_info: {
          uid: uid,
          first_name: firstName,
          last_name: lastName,
          photo_url: photoUrl,
        },
        created_at: Firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(docRef => {
        resolve(docRef.id)
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}

export const fetchMakerReviews = (makerId, limit, startAfter) => {
  return new Promise((resolve, reject) => {
    let query = firebaseApp
      .firestore()
      .collection('users')
      .doc(makerId)
      .collection('reviews')
      .orderBy('created_at', 'desc')
      .limit(limit)
    if (startAfter) {
      query = query.startAfter(startAfter)
    }
    query
      .get()
      .then(querySnapshot => {
        const lastElement = querySnapshot.docs[querySnapshot.docs.length - 1]
        const reviews = querySnapshot.docs.map(doc => ({
          ...doc.data(),
          uid: doc.id,
        }))
        resolve({ reviews, lastElement })
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}

export const updateMakerReviews = (makerId, newReview) => {
  const {
    qualityAccuracy,
    qualityCounter,
    price,
    priceCounter,
    profesionalism,
    profesionalismCounter,
    promptness,
    promptnessCounter,
  } = newReview
  return new Promise((resolve, reject) => {
    return firebaseApp
      .firestore()
      .collection('users')
      .doc(makerId)
      .update({
        reviews: {
          quality_accuracy: qualityAccuracy,
          quality_counter: qualityCounter,
          price: price,
          price_counter: priceCounter,
          profesionalism: profesionalism,
          profesionalism_counter: profesionalismCounter,
          promptness: promptness,
          promptness_counter: promptnessCounter,
        },
        updated_at: Firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        resolve(newReview)
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}

const reauthenticateFirebaseUser = (user, currentPassword) => {
  const credential = Firebase.auth.EmailAuthProvider.credential(
    user.email,
    currentPassword
  )
  return user.reauthenticateWithCredential(credential)
}

export const updatePassword = (currentPassword, newPassword) => {
  return new Promise((resolve, reject) => {
    const { currentUser } = getAuth()
    if (
      currentUser &&
      currentUser.providerData.find(
        provider => provider.providerId === 'password'
      )
    ) {
      reauthenticateFirebaseUser(currentUser, currentPassword)
        .then(() => {
          currentUser
            .updatePassword(newPassword)
            .then(() => {
              resolve(newPassword)
            })
            .catch(error => {
              reject(error)
            })
        })
        .catch(error => {
          reject(error)
        })
    }
  })
}
