import { red } from '@material-ui/core/colors'
import { createMuiTheme } from '@material-ui/core/styles'

import RalewayWoff2 from './fonts/raleway-regular.woff2'

const raleway = {
  fontFamily: 'Raleway',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Raleway'),
    local('Raleway-Regular'),
    url(${RalewayWoff2}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}

const theme = createMuiTheme({
  mixins: {
    toolbar: {
      minHeight: '64px',
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        fontSize: '1.125rem',
      },
    },
    MuiCheckbox: {
      root: {
        color: '#303030',
      },
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': [raleway],
      },
    },
  },
  palette: {
    background: {
      default: '#4B4E6D',
      darkblue: '#483447',
      gray: '#707070',
      green: '#80b192',
      lightgray: '#d0d0d0',
      main: '#F7F9FD',
      red: red.A400,
      white: '#FFF',
    },
    border: {
      lightgray: '#d0d0d0',
      shadow: '#3457DC22',
      blue: '#4040ff',
    },
    error: {
      main: red.A400,
    },
    primary: {
      main: '#80B192',
    },
    secondary: {
      main: '#6B8D92',
    },
    tertiary: {
      main: '#6A8D92',
    },
    text: {
      alert: '#C2750C',
      allowed: '#875117',
      default: '#4C4B49',
      gray: '#5D6068',
      link: '#0045FF',
      linkHover: '#493548',
      pearl: '#F7F9FD',
      primary: '#000',
      secondary: '#000',
      white: '#FFF',
    },
  },
  typography: {
    fontFamily: `Raleway, 'Roboto'`,
  },
  shape: {
    borderRadius: 4,
  },
  custom: {
    form: {
      checkboxFontSize: '0.75rem',
      text: {
        primary: '#000',
        secondary: '#C3C3C3',
      },
    },
  },
})

export default theme
