import {
  FETCH_CATALOG_FAILURE,
  FETCH_CATALOG_REQUEST,
  FETCH_CATALOG_SUCCESS,
} from 'constants/catalogConstants'
import { store } from '../store'
import { fetchCatalog } from 'services/CatalogService'

const { dispatch } = store

export const fetchCatalogFailure = error => ({
  type: FETCH_CATALOG_FAILURE,
  error,
})

export const fetchCatalogRequest = () => ({
  type: FETCH_CATALOG_REQUEST,
})

export const fetchCatalogSuccess = catalog => ({
  type: FETCH_CATALOG_SUCCESS,
  catalog,
})

export const fetchAllCatalog = async () => {
  dispatch(fetchCatalogRequest())
  try {
    let catalog = localStorage.getItem('calalog')
    if (catalog) {
      catalog = JSON.parse(catalog)
    } else {
      catalog = await fetchCatalog()
    }
    dispatch(fetchCatalogSuccess(catalog))
  } catch (err) {
    dispatch(fetchCatalogFailure(err))
  }
}
