export const AVATAR_FOLDER_PATH = 'avatar_pics'
export const EMAIL = 'email'
export const ERROR_ADDING_PERSONAL_INFO =
  'An error occurred while saving the user, please try again later.'
export const ERROR_CREATING_ACCOUNT =
  'An error occurred while creating your account, please try again later.'
export const ERROR_EMAIL_ALREADY_USED =
  'The email address is already in use by another account.'
export const ERROR_EMAIL_UNVERIFIED = `The user's email isn't verified.`
export const ERROR_FETCHING_HERO_IMAGES =
  'An error occurred while fetching the carousel images, please try again later.'
export const ERROR_FETCHING_MODELS =
  'An error occurred while fetching models, please try again later.'
export const ERROR_FETCHING_USER =
  'An error occurred while fetching user, please try again later.'
export const ERROR_NOT_LOGGED_IN = 'No user logged in.'
export const ERROR_USER_NOT_IN_DB = 'The user is not in the DB.'
export const INVALID_EMAIL = 'Invalid email address.'
export const INVALID_FIELD = field => `Please enter a valid ${field}.`
export const INVALID_PASSWORD =
  'Passwords must contain 8 to 15 characters, including at least one lowercase letter, one uppercase letter, one numeric digit, and one special character.'
export const MISMATCHED = `The passwords don't match.`
export const PASSWORD = 'password'
export const USER_STATUS_ACTIVE = 'ACTIVE'
export const USER_STATUS_PENDING_ACTIVATION = 'PENDING_ACTIVATION'
export const USER_MENU = {
  DESIGNS: 'designs',
  LIST: 'list',
  LOGOUT: 'logout',
  OFFERS: 'offers',
  REVIEWS: 'reviews',
  SETTINGS: 'settings',
  STORE: 'store',
}

export const FETCH_CURRENT_USER_ERROR = 'FETCH_CURRENT_USER_ERROR'
export const FETCH_CURRENT_USER_REQUEST = 'FETCH_CURRENT_USER_REQUEST'
export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS'
export const LOG_OUT_REQUEST = 'LOG_OUT_REQUEST'
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS'
export const UPDATE_CURRENT_USER_FAILURE = 'UPDATE_CURRENT_USER_FAILURE'
export const UPDATE_CURRENT_USER_REQUEST = 'UPDATE_CURRENT_USER_REQUEST'
export const UPDATE_CURRENT_USER_SUCCESS = 'UPDATE_CURRENT_USER_SUCCESS'
