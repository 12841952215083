import {
  CONFIG_CAT_FAILURE,
  CONFIG_CAT_REQUEST,
  CONFIG_CAT_SUCCESS,
} from 'constants/featureFlagConstants'

const defaultState = {
  client: {},
  isLoading: false,
  flags: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case CONFIG_CAT_FAILURE: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case CONFIG_CAT_REQUEST: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case CONFIG_CAT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        client: action.client,
        flags: action.flags,
      }
    }
    default:
      return state
  }
}
