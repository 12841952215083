import getFirebase from './firebase'

const firebaseApp = getFirebase()

const getCatalog = () => firebaseApp.firestore().collection('catalog')

export const fetchCatalog = () => {
  return new Promise((resolve, reject) => {
    if (firebaseApp) {
      getCatalog()
        .get()
        .then(item => {
          item.forEach(doc => {
            //  this will return the categories, manufacturers, materials and
            //  print technology
            localStorage.setItem('calalog', JSON.stringify(doc.data()))
            resolve(doc.data())
          })
        })
        .catch(error => {
          console.log(error.message)
          reject(error)
        })
    }
  })
}
