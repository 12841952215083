export const ABOUT_US_ENDPOINT = '/about'
export const CONTACT_US_ENDPOINT = '/contact'
export const DASHBOARD_ENDPOINT = '/dashboard'
export const EAP_ENDPOINT = '/eap'
export const HOME_ENDPOINT = '/'
export const LISTINGS_DETAILS_ENDPOINT = listingId => `/listings/${listingId}`
export const LISTINGS_ENDPOINT = '/listings'
export const MODEL_DETAILS_ENDPOINT = name => `/designs/${name}`
export const NOT_FOUND_ENDPOINT = '/404'
export const ONBOARDING_ENDPOINT = '/onboarding'
export const PROFILE_ENDPOINT = '/profile'
export const SIGNUP_ENDPOINT = '/signup'
export const SIGNUP_INSTRUCTIONS_ENDPOINT = '/instructions'
export const TERMS_OF_SERVICE_ENDPOINT = '/terms-of-service'
export const USER_PROFILE_ENDPOINT = userId => `/profiles/${userId}`
export const THIRD_PARTY_ONBOARDING = '/third-party-onboarding'
