import { applyMiddleware, createStore, combineReducers } from 'redux'
import thunk from 'redux-thunk'

import algoliaState from './reducers/algoliaReducer'
import catalogState from './reducers/catalogReducer'
import featureFlagState from './reducers/featureFlagReducer'
import signupState from './reducers/signupReducer'
import userState from './reducers/userReducer'

const reducers = {
  algoliaState,
  catalogState,
  featureFlagState,
  signupState,
  userState,
}

export const store = createStore(
  combineReducers({
    ...reducers,
  }),
  applyMiddleware(thunk)
)
